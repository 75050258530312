import { Language } from "@sixty-six-north/i18n"
import {
  CategoryPagedQueryResponse,
  ClientResponse
} from "@commercetools/platform-sdk"
import { ByProjectKeyRequestBuilder } from "@commercetools/platform-sdk/dist/declarations/src/generated/client/by-project-key-request-builder"
import { ApiRequest } from "@commercetools/platform-sdk/dist/declarations/src/generated/shared/utils/requests-utils"
import { memoizedFn, memoTimeout } from "../Cache"
import { ServerSideCommerceToolsClient } from "../commercetools/CommerceToolsClient"
import { getNextOffsets } from "../commercetools/Pagination"
import {
  DomainCategories,
  DomainCategory
} from "../product/models/DomainCategory"
import { createLogger } from "../utils/createLogger"
import { Paginator } from "../utils/PaginatedRequests"

const logger = createLogger("CategoryDal")

class CategoryDal {
  private root: <T>(
    project: (it: ByProjectKeyRequestBuilder) => ApiRequest<T>
  ) => Promise<ClientResponse<T>>
  private expand = "ancestors[*]"

  private queryArgs = { expand: this.expand }

  constructor(
    root: <T>(
      project: (it: ByProjectKeyRequestBuilder) => ApiRequest<T>
    ) => Promise<ClientResponse<T>>
  ) {
    this.root = root
  }

  public async all(): Promise<DomainCategory[]> {
    return new Paginator(
      () => this.allCategoriesWithOffset(0),
      page =>
        getNextOffsets(page).map(page => this.allCategoriesWithOffset(page)),
      5000
    )
      .fetchAll()
      .then(responses => responses.flatMap(resp => resp.results))
      .then(it => it.map(DomainCategories.fromCategory))
  }

  public allCategoriesWithOffset = (
    offset: number
  ): Promise<CategoryPagedQueryResponse> => {
    return this.root<CategoryPagedQueryResponse>(it =>
      it.categories().get({
        queryArgs: { ...this.queryArgs, offset, sort: "orderHint desc" }
      })
    )
      .then(it => it.body)
      .catch(error => {
        logger.error("Error fetching page at offset", offset)
        throw error
      })
  }

  public byId(id: string): Promise<DomainCategory> {
    return this.root<CategoryPagedQueryResponse>(it =>
      it
        .categories()
        .get({ queryArgs: { where: `id = "${id}"`, ...this.queryArgs } })
    )
      .then(it => it.body.results[0])
      .then(result => {
        if (result) {
          return DomainCategories.fromCategory(result)
        } else {
          return result
        }
      })
  }

  public bySlug(slug: string, language: Language): Promise<DomainCategory> {
    const fetchCategory = () => {
      return this.root<CategoryPagedQueryResponse>(it =>
        it.categories().get({
          queryArgs: {
            where: `slug(${language}="${slug}")`,
            ...this.queryArgs
          }
        })
      )
        .then(it => it.body.results[0])
        .then(result => {
          if (result) {
            return DomainCategories.fromCategory(result)
          } else {
            return result
          }
        })
    }

    return memoizedFn(
      `category-by-slug-${slug}-${language}`,
      memoTimeout.minutes(1),
      () => fetchCategory()
    )
  }
}

export const categoryDal = new CategoryDal(
  ServerSideCommerceToolsClient.execute.bind(ServerSideCommerceToolsClient)
)

const byOrderHint = (left: DomainCategory, right: DomainCategory) =>
  +right.orderHint - +left.orderHint

export const getRootCategories = (
  categories: DomainCategory[]
): DomainCategory[] => {
  return categories.filter(it => it.ancestors.length === 0).sort(byOrderHint)
}
